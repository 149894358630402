// import scss
@import "./Color.scss";

// import font face
// @import url("https://fonts.googleapis.com/css?family=Sarabun:400, 500&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Prompt&display=swap");
// @font-face {
//      font-family: "Anakotmai";
//      font-style: normal;
//      font-weight: bold;
//      src: url("../theme/webfont/anakotmai-bold.eot") format("embedded-opentype"), url("../theme/webfont/anakotmai-bold.woff2") format("woff2"),
//           url("../theme/webfont/anakotmai-bold.woff") format("woff");
// }
@font-face {
     font-family: "Anakotmai";
     font-style: normal;
     font-weight: 600;
     font-size: 18;
     src: url("../theme/webfont/anakotmai-medium.eot") format("embedded-opentype"), url("../theme/webfont/anakotmai-medium.woff2") format("woff2"),
          url("../theme/webfont/anakotmai-medium.woff") format("woff");
}

@font-face {
     font-family: "Anakotmai";
     font-style: normal;
     font-weight: 400;
     font-size: 18;
     src: url("../theme/webfont/anakotmai-light.eot") format("embedded-opentype"), url("../theme/webfont/anakotmai-light.woff2") format("woff2"),
          url("../theme/webfont/anakotmai-light.woff") format("woff");
}

body {
     // font-size: 18px !important;
     font-family: "Anakotmai" !important;
     // color: $default-color;
     // background-color: $background;
     padding: 0 !important;
     margin: 0 !important;
}

//-----------------------------------------------------------------------------//
//------------------------------------ ออส ------------------------------------//
//-----------------------------------------------------------------------------//
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/* Firefox */
input[type="number"] {
     -moz-appearance: textfield;
}

.textfield .fonts-color .MuiSelect-outlined.MuiSelect-outlined {
     padding-right: 32px !important;
     color: #a0a5a8 !important;
}

/*--------------- grid-noti -----------------*/
.grid-noti {
     background: rgb(233, 236, 239);
     opacity: 0.9;
     cursor: pointer;
     padding: 0.5rem;
}

.grid-noti:hover {
     background: #ccc;
     opacity: 0.9;
     cursor: pointer;
     padding: 0.5rem;
}

//-----------------------------------------------------------------------------//
//-----------------------------------------------------------------------------//
//-----------------------------------------------------------------------------//
/* ---- login layout ---- */
.main-height-login {
     height: 100vh;
     // display: flex;
     // justify-content: center;
     // align-items: center;
     // background: #000;
}

.background-login {
     top: 0;
     left: 0;
     width: 100%;
     height: 100vh;
     display: block;
     z-index: 0;
     position: absolute;
     background-size: cover;
     background-position: center center;
}

.login-height {
     height: 100%;
}

.eye-btn {
     cursor: pointer;
}

.eye-btn:hover {
     background-color: #dee2e6;
     border-radius: 0.7rem;
}

/* --------------------- */
/* ----- link-head ------ */
.link-head {
     // margin-left: 1rem !important;
     color: white !important;
     // font-size: 13px !important;
}

.link-head-active {
     color: white !important;
     // text-decoration: underline !important;
     // font-size: 13px !important;
}

.link-head-minor {
     // margin-left: 1rem !important;
     color: white !important;
     // font-size: 13px !important;
}

.link-head-minor-active {
     color: white !important;
}

.link-head-minor-menu {
     // margin-left: 1rem !important;
     color: #000 !important;
     // font-size: 13px !important;
}

.link-head-minor-menu:hover {
     color: #000 !important;
}

.css-ts1vx5-MuiTypography-root-MuiLink-root {
     text-decoration: unset !important;
     // color: white !important;
}

.css-ts1vx5-MuiTypography-root-MuiLink-root:hover {
     text-decoration: unset !important;
     color: white !important;
}

/* --------------------- */
/* ----- slidebar ------ */
.slidebar-active {
     color: #fff !important;
     font-weight: 500 !important;
     background: linear-gradient(to right, #602d30, #212529) !important;
}

.slidebar {
     color: #fff !important;
     width: 100% !important;
     padding: 10px 8px !important;
     font-weight: 500 !important;
     letter-spacing: 0 !important;
     text-transform: none !important;
     justify-content: flex-start !important;
}

// .background-slidebar {
//      top: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      display: block;
//      z-index: 0;
//      position: absolute;
//      background-size: cover;
//      background-position: center center;
// }

// .background-slidebar:after {
//      width: 100%;
//      height: 100%;
//      content: "";
//      display: block;
//      opacity: 0.8;
//      z-index: 1;
//      position: absolute;
//      background: #000;
// }

/* --------------------- */
/* ---- main layout ---- */
.main-height {
     height: 100vh;
}

.header-height {
     height: 7%;
}

.component-height {
     height: 93%;
}

.body-height {
     min-height: 93%;
}

/* -------------------------- */
/* ---- not found layout ---- */
.notfound-layout-height {
     height: 100%;
}

/* ---------------------------- */
/* ---- custom-file-upload ---- */
.custom-file-upload {
     cursor: pointer;
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
}

.custom-file-upload input[type="file"] {
     display: none;
}

/* -------------------------- */
/* --------- table ---------- */
.header-detail-order {
     color: #fff !important;
     background: #184b8e !important;
     text-align: center !important;
}

.MuiTableHead-root {
     display: table-header-group !important;
     background: #4589C6 !important;
}

.MuiTableCell-head {
     color: #ffffff !important;
     font-weight: 500 !important;
     line-height: 1.5rem !important;
}

.table-btn .MuiButton-outlinedPrimary {
     color: #184b8e !important;
     border: 1px solid #184b8e !important;
}

.bg-table {
     background-color: rgba(0, 0, 0, 0.025);
}

/* -------------------------- */
/* ------------- pagination ------------- */
@media screen and (max-width: 344px) {
     .MuiPaginationItem-root {
          color: #4589C6 !important;
          border-color: #4589C6 !important;
          height: 26px !important;
          min-width: 26px !important;
          margin: 0 2px !important;
          padding: 0 6px;
          font-size: 0.625rem !important;
          box-sizing: border-box !important;
          text-align: center !important;
          font-family: Prompt !important;
          font-weight: 400 !important;
          line-height: 1.43 !important;
          border-radius: 16px !important;
     }
}

@media screen and (min-width: 345px) and (max-width: 399px) {
     .MuiPaginationItem-root {
          color: #4589C6 !important;
          border-color: #4589C6 !important;
          height: 28px !important;
          min-width: 28px !important;
          margin: 0 2px !important;
          padding: 0 6px;
          font-size: 0.785rem !important;
          box-sizing: border-box !important;
          text-align: center !important;
          font-family: Prompt !important;
          font-weight: 400 !important;
          line-height: 1.43 !important;
          border-radius: 16px !important;
     }
}

@media screen and (min-width: 400px) {
     .MuiPaginationItem-root {
          color: #4589C6 !important;
          border-color: #4589C6 !important;
          height: 32px !important;
          margin: 0 3px !important;
          padding: 0 6px;
          font-size: 0.875rem !important;
          min-width: 32px !important;
          box-sizing: border-box !important;
          text-align: center !important;
          font-family: Prompt !important;
          font-weight: 400 !important;
          line-height: 1.43 !important;
          border-radius: 16px !important;
     }
}

.MuiPaginationItem-outlinedSecondary.Mui-selected {
     color: #fff !important;
     border: transparent !important;
     background: #4589C6 !important;
}

/* -------------------------- */
/* ------------- datepicker ------------- */
.border-datepicker .MuiInputBase-root {
     color: #263238;
     cursor: pointer !important;
     display: inline-flex;
     position: relative;
     font-size: 1rem;
     box-sizing: border-box;
     align-items: center;
     font-family: Prompt;
     font-weight: 400;
     line-height: 1.1876em;
     border: 1px solid #c4c4c4;
     padding-left: 11px;
     height: 40px;
     border-radius: 3px;
}

.border-datepicker .MuiInputBase-root input {
     cursor: pointer !important;
}

.border-datepicker .MuiInput-underline:before {
     left: 0;
     right: 0;
     bottom: 0;
     content: "\00a0";
     position: absolute;
     transition: unset;
     border-bottom: none;
     pointer-events: none;
}

/* -------------------------- */
/* ------------ doc modal ------------ */
@media screen and (min-width: 390px) {}

@media screen and (max-width: 389px) {}

/* -------------------------- */
/* ------------ zoom image ------------- */
.zoom-image img {
     transition: all 1s ease;
     // cursor: zoom-in;
}

.zoom-image {
     overflow: scroll;
}

/* -------------------------- */
/* ------------ focut unset ------------- */
.MuiContainer-root:focus {
     outline: unset !important;
}

.modal-outline-unset:focus {
     outline: unset !important;
}

button:focus {
     outline: unset !important;
}

/* -------------------------------------- */
/* ------------ drawer ------------- */
// .background-drawer {
//      top: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      display: block;
//      z-index: 0;
//      position: absolute;
//      background-size: cover;
//      background-position: center center;
// }

// .background-drawer:after {
//      width: 100%;
//      height: 100%;
//      content: "";
//      display: block;
//      opacity: 0.6;
//      z-index: 1;
//      position: absolute;
//      background: #000;
// }
/*------------ edit-outlined --------------*/
.edit-outlined {
     cursor: pointer;
}

.edit-outlined:hover {
     color: red;
}

/* -------------------------- */
/*------------ add-iconadd --------------*/
.add-iconadd {
     cursor: pointer;
}

.add-iconadd:hover {
     color: #28a745;
}

/* -------------------------- */
/*------------ btn-sharing --------------*/
.btn-sharing {
     background: linear-gradient(to right, #602d30, #212529) !important;
     color: white !important;
     width: 50px !important;
}

.btn-sharing-del {
     background: linear-gradient(to right, #bc101c, #5d0309) !important;
     color: white !important;
     width: 50px !important;
}

///////////////////////////////////////////////////
/*--------------- textarea-css -----------------*/
///////////////////////////////////////////////////
textarea {
     width: calc(100% - 30px) !important;
     font-family: "Anakotmai" !important;
     max-width: calc(100% - 30px) !important;
     // border-color: transparent !important;
     font-size: 1rem !important;
     color: #000 !important;
     background-color: #f3f3f3 !important;
     border-radius: 7px !important;
     padding: 8.5px 14px !important;
}

textarea:focus {
     outline: none !important;
}

/////////////////////////////////////////////////////////
/*--------------- textfield-css-login -----------------*/
/////////////////////////////////////////////////////////
// .textfield-css-login input {
//      color: #fff !important;
// }
// .typography-css-login .MuiTypography-root {
//      color: #fff !important;
//      font-size: 20px !important;
// }
// .textfield-css-login {
//      background-color: rgba(0, 0, 0, 0.7) !important;
//      border-radius: 5px !important;
//      max-width: 500px !important;
// }
// .textfield-css-login .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
//      color: #000 !important ;
// }

.textfield-css-login .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6;
}

.textfield-css-login .MuiOutlinedInput-root:focus {
     outline: unset !important;
}

.textfield-css-login .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}

.textfield-css-login .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #56B3E5 !important;
     border-width: 1px !important;
}

.textfield-css-login .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6 !important;
}

.textfield-css-login .MuiSelect-icon {
     top: calc(50% - 12px);
     color: #fff;
     position: absolute;
     pointer-events: none;
}

.textfield-css-login .MuiFormLabel-root.Mui-focused {
     color: #fff !important;
}

.textfield-css-login .MuiInputLabel-shrink {
     color: #fff !important;
}

.textfield-css-login .MuiSvgIcon-root {
     color: #fff !important;
     margin-right: 10px !important;
     margin-top: -2px !important;
}

.textfield-css-login ::placeholder {
     /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: #D4D4D4 !important;
     opacity: unset !important;
     /* Firefox */
}

.textfield-css-login :-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color: #fff !important;
}

.textfield-css-login ::-ms-input-placeholder {
     /* Microsoft Edge */
     color: #fff !important;
}

/////////////////////////////////////////////////////////
/*--------------- textfield-password -----------------*/
/////////////////////////////////////////////////////////

.textfield-css-login-password .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6;
}

.textfield-css-login-password .MuiOutlinedInput-root:focus {
     outline: unset !important;
}

.textfield-css-login-password .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}

.textfield-css-login-password .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #56B3E5 !important;
     border-width: 1px !important;
}

.textfield-css-login-password .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6 !important;
}

.textfield-css-login-password .MuiSelect-icon {
     top: calc(50% - 12px);
     color: #fff;
     position: absolute;
     pointer-events: none;
}

.textfield-css-login-password .MuiFormLabel-root.Mui-focused {
     color: #fff !important;
}

.textfield-css-login-password .MuiInputLabel-shrink {
     color: #fff !important;
}

.textfield-css-login-password .MuiSvgIcon-root {
     // color: #fff !important;
     margin-right: 10px !important;
     margin-top: -2px !important;
}

.textfield-css-login-password ::placeholder {
     /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: #D4D4D4 !important;
     opacity: unset !important;
     /* Firefox */
}

.textfield-css-login-password :-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color: #fff !important;
}

.textfield-css-login-password ::-ms-input-placeholder {
     /* Microsoft Edge */
     color: #fff !important;
}

/////////////////////////////////////////////////////////
/*--------------- textfield-profile -----------------*/
/////////////////////////////////////////////////////////
.textfield-profile input {
     color: black !important;
}

.typography-profile .MuiTypography-root {
     color: #060606 !important;
     font-size: 18px !important;
     font-weight: bold !important;
}

.textfield-profile {
     background-color: white !important;
     border-radius: 5px !important;
     // max-width: 500px !important;
}

// .textfield-profile .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
//      color: #000 !important ;
// }

.textfield-profile .MuiOutlinedInput-notchedOutline {
     border-color: #ebebeb;
}

.textfield-profile .MuiOutlinedInput-root:focus {
     outline: unset !important;
}

.textfield-profile .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}

.textfield-profile .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #ebebeb !important;
     border-width: 1px !important;
}

.textfield-profile .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #ebebeb !important;
}

.textfield-profile .MuiSelect-icon {
     top: calc(50% - 12px);
     color: black;
     position: absolute;
     pointer-events: none;
}

.textfield-profile .MuiFormLabel-root.Mui-focused {
     color: black !important;
}

.textfield-profile .MuiInputLabel-shrink {
     color: black !important;
}

.textfield-profile .MuiSvgIcon-root {
     // color: black !important;
     color: #707070 !important;
     margin-right: 10px !important;
     margin-top: -2px !important;
}

//------------ placeholder ------------------//
.textfield-profile ::placeholder {
     /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: #707070 !important;
     opacity: unset !important;
     /* Firefox */
}

.textfield-profile :-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color: #707070 !important;
}

.textfield-profile ::-ms-input-placeholder {
     /* Microsoft Edge */
     color: #707070 !important;
}

/////////////////////////////////////////////////////////
/*------------------- grid-date-field -----------------*/
/////////////////////////////////////////////////////////
.grid-date-field .MuiInput-underline.Mui-disabled:before {
     border-bottom-style: unset !important;
}

.grid-date-field .MuiInputBase-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.38);
     cursor: default;
     background: white !important;
}

.border-datepicker .MuiInputBase-root {
     color: #263238;
     cursor: text;
     display: inline-flex;
     position: relative;
     font-size: 1rem;
     box-sizing: border-box;
     align-items: center;
     font-family: Prompt;
     font-weight: 400;
     line-height: 1.1876em;
     border: 1px solid #602d30 !important;
     padding-left: 11px;
     height: 40px;
     border-radius: 5px;
     background-color: white;
}

.border-datepicker .MuiInput-underline:before {
     left: 0;
     right: 0;
     bottom: 0;
     content: "\00a0";
     position: absolute;
     transition: unset;
     border-bottom: none;
     pointer-events: none;
}

.border-datepicker .MuiIconButton-label {
     color: #602d30;
}

.MuiPickersToolbar-toolbar {
     height: 100px;
     display: flex;
     align-items: center;
     flex-direction: row;
     justify-content: center;
     background: linear-gradient(to bottom, #602d30, #212529) !important;
}

.MuiPickersDay-daySelected {
     color: #ffffff !important;
     font-weight: 500;
     background-color: #602d30 !important;
}

.MuiPickersDay-daySelected p {
     color: #ffffff !important;
}

// .MuiDialogActions-root .MuiButton-textPrimary {
//      color: #602d30 !important;
// }
//////////////////////////////////////
.MuiInput-underline:after {
     border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
     border-bottom: none !important;
}

.MuiInput-underline:focus:not(.Mui-disabled):before {
     border-bottom: none !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     padding-right: 65px;
     background: white !important;
}

//////////////////////////////////////
.MuiRadio-root {
     color: #4589C6 !important;
}

//////////////////////////////////////
//////////////////////////////////////
/*------------------- grid-text-field -------------------*/
.grid-text-field .MuiIconButton-label {
     color: #602d30;
}

.grid-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #602d30 !important;
     border-width: 1px !important;
}

.grid-text-field .MuiOutlinedInput-notchedOutline {
     border-color: #602d30;
}

.grid-text-field .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}

.grid-text-field .MuiInputBase-input.Mui-disabled {
     opacity: 1;
     background: white !important;
}

.grid-text-field .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
     border-color: #602d30;
}

.grid-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #602d30 !important;
}

.MuiInput-underline:after {
     border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
     border-bottom: none !important;
}

.MuiInput-underline:focus:not(.Mui-disabled):before {
     border-bottom: none !important;
}

.grid-text-field .MuiInputBase-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.38);
     cursor: default;
     background: white !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     padding-right: 65px;
     background: white !important;
}

//////////////////////////////////////
//////////////////////////////////////
.main-header .MuiToolbar-root {
     padding-left: 0 !important;
     padding-right: 0 !important;
}

.parallax-div {
     height: 100vh;
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     text-align: center;
     background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     background-position: center top;
     background-size: cover;
}

.main-appbar .css-hz1bth-MuiDialog-container {
     background: linear-gradient(rgba(10, 10, 10, 0.8), rgba(10, 10, 10, 0.8)), url("../assets/images/backdrop-menu.png") !important;
     background-position: center center !important;
     background-size: cover !important;
}

.main-appbar .css-zcllit-MuiPaper-root-MuiDialog-paper {
     height: 100% !important;
     max-height: unset !important;
     width: 100vw !important;
     max-width: unset !important;
     color: white !important;
     margin: 0 !important;
     border-radius: 0 !important;
     background-color: transparent !important;
     box-shadow: unset !important;
}

.MuiButton-outlined,
.MuiButton-outlinedError {
     border: 1px solid #4589C6 !important;
     color: #4589C6 !important;
}

.main-pop {
     color: white !important;
     background-color: rgba(10, 10, 10, 0.88) !important;
}

.css-1w876q2-MuiPaper-root-MuiPopover-paper {
     background-color: transparent !important;
     top: 65px !important;
}

.css-1yxzyzw-MuiBackdrop-root {
     background-color: rgba(0, 0, 0, 0.88) !important;
}

@media screen and (max-width: 400px) {
     .loadingSvg {
          height: 200px !important;
          width: auto !important;
          transform: scale(0.91);
          // transform-origin: 50px 50px;
          animation: 1.11111s linear -1.11111s infinite normal forwards running breath-182ea9e8-d72c-4515-a330-e60285129267;
          animation-name: loading;
     }
}

@media screen and (min-width: 401px) {
     .loadingSvg {
          height: 350px !important;
          width: auto !important;
          transform: scale(0.91);
          // transform-origin: 50px 50px;
          animation: 1.11111s linear -1.11111s infinite normal forwards running breath-182ea9e8-d72c-4515-a330-e60285129267;
          animation-name: loading;
     }
}

.loading-animation {
     // transform-origin: 50% 50%;
     transform: rotate(0deg) scale(0.3, 0.3);
}

@keyframes loading {
     0% {
          transform: scale(0.9099999999999999);
     }

     50% {
          transform: scale(1.02994);
     }

     100% {
          transform: scale(0.9099999999999999);
     }
}

@media screen and (max-width: 599px) {
     .footer {
          // height: 300px;
          // background-image: linear-gradient(rgba(10, 10, 10, 0.8), rgba(10, 10, 10, 0.8)), url("../assets/images/backdrop-menu.png");
          // background-position: center top;
          // background-size: cover;
          background-color: #707070;
          position: relative;
     }

     .parallax-image-logo {
          width: auto !important;
          height: 120px !important;
          z-index: 1 !important;
          margin-top: 40px !important;
          object-fit: contain !important;
          margin-bottom: 24px !important;
     }

     .parallax-title-logo {
          color: #ffffff !important;
          font-size: 50px !important;
          font-weight: 600 !important;
          margin-bottom: 24px !important;
          z-index: 1 !important;
     }

     .parallax-detail-logo {
          font-size: 18px !important;
          color: #ccc !important;
          z-index: 1 !important;
          white-space: break-spaces !important;
          margin-bottom: 24px !important;
     }

     .parallax-btn-logo {
          border-radius: 40px !important;
          font-size: 18px !important;
          z-index: 1 !important;
     }
}

@media screen and (min-width: 600px) {
     .footer {
          // height: 300px;
          // background-image: url("../assets/images/footage.png");
          // background-position: right center;
          // background-size: cover;
          background-color: #707070;
          position: relative;
     }
}

.background-main-promotion {
     background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url("../assets/images/backdrop-menu.png") !important;
     background-position: center center !important;
     background-size: cover !important;
}

// .background-page-layout {
//      height: 400px;
//      width: 100vw;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      flex-direction: column;
//      text-align: center;
//      background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
//      background-position: center center;
//      background-size: cover;
// }
@media screen and (max-width: 899px) {
     .btn-style {
          border-radius: 40px !important;
          font-size: 13px !important;
          width: 100% !important;
          // max-width: 300px !important;
     }

     .policy-page .css-15v22id-MuiAccordionDetails-root {
          padding: 0px !important;
          margin-top: 16px !important;
          margin-bottom: 16px !important;
          background-color: #f3f3f3 !important;
          border-radius: 7px !important;
     }
}

@media screen and (min-width: 900px) {
     .btn-style {
          border-radius: 40px !important;
          font-size: 18px !important;
          width: 100% !important;
          // max-width: 300px !important;
     }

     .policy-page .css-15v22id-MuiAccordionDetails-root {
          padding: 0px !important;
          margin-top: 16px !important;
          margin-bottom: 16px !important;
     }
}

.detail-contact {
     background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("../assets/images/backdrop-menu.png") !important;
     background-position: center center !important;
     background-size: cover !important;
     height: 100% !important;
     width: 100% !important;
     display: flex !important;
     align-items: center !important;
}

.policy-page .css-yodw6m-MuiPaper-root-MuiAccordion-root {
     box-shadow: unset !important;
     position: unset !important;
}

.policy-page .css-i4bv87-MuiSvgIcon-root {
     font-size: 2.5rem !important;
     color: #4589C6 !important;
}

.policy-page .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
     border-bottom: 2px solid #4589C6 !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
}

//------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------//
.question-page .css-yodw6m-MuiPaper-root-MuiAccordion-root {
     box-shadow: unset !important;
     position: unset !important;
}

.question-page .css-i4bv87-MuiSvgIcon-root {
     font-size: 2.5rem !important;
     color: #4589C6 !important;
}

.question-page .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
     border-bottom: 2px solid #4589C6 !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
}

.question-page .css-15v22id-MuiAccordionDetails-root {
     padding: 0px !important;
}

//------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------//
.question-page-body {
     padding: 16px !important;
     background-color: #f3f3f3 !important;
     border-radius: 7px !important;
     margin-top: 16px !important;
     margin-bottom: 16px !important;
}

.question-page-body .css-yodw6m-MuiPaper-root-MuiAccordion-root {
     background-color: transparent !important;
}

.question-page-body .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
     border-bottom: unset !important;
}

//------------------------------------------------------------------------------------//

//------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------//
@media screen and (max-height: 470px) and (max-width: 599px) {
     .parallax-image-logo {
          width: auto !important;
          height: 90px !important;
          z-index: 1 !important;
          margin-top: 45px !important;
          object-fit: contain !important;
          margin-bottom: 15px !important;
     }

     .parallax-title-logo {
          color: #ffffff !important;
          font-size: 30px !important;
          font-weight: 600 !important;
          margin-bottom: 15px !important;
          z-index: 1 !important;
     }

     .parallax-detail-logo {
          font-size: 14px !important;
          color: #ccc !important;
          z-index: 1 !important;
          white-space: break-spaces !important;
          margin-bottom: 15px !important;
     }

     .parallax-btn-logo {
          border-radius: 40px !important;
          font-size: 14px !important;
          z-index: 1 !important;
     }
}

@media screen and (max-height: 369px) and (min-width: 600px) {
     .parallax-image-logo {
          width: auto !important;
          height: 40px !important;
          z-index: 1 !important;
          margin-top: 110px !important;
          object-fit: contain !important;
          margin-bottom: 10px !important;
     }

     .parallax-title-logo {
          color: #ffffff !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          margin-bottom: 10px !important;
          z-index: 1 !important;
     }

     .parallax-detail-logo {
          font-size: 10px !important;
          color: #ccc !important;
          z-index: 1 !important;
          white-space: break-spaces !important;
          margin-bottom: 10px !important;
     }

     .parallax-btn-logo {
          border-radius: 40px !important;
          font-size: 10px !important;
          z-index: 1 !important;
     }

     /* --------------------- */
     /* ----- title-menu ------ */

     .title-menu {
          // margin-left: 1.25rem !important;
          font-size: 12px !important;
     }

     .btn-menu {
          border-radius: 40px !important;
          font-size: 12px !important;
     }

     .minor-header {
          top: 50px !important;
          z-index: 1 !important;
          width: 100vw !important;
          position: absolute !important;
     }

     .css-1t29gy6-MuiToolbar-root {
          min-height: 45px !important;
     }
}

@media screen and (min-height: 370px) and (max-height: 690px) and (min-width: 600px) {
     .parallax-image-logo {
          width: auto !important;
          height: 60px !important;
          z-index: 1 !important;
          margin-top: 150px !important;
          object-fit: contain !important;
          margin-bottom: 14px !important;
     }

     .parallax-title-logo {
          color: #ffffff !important;
          font-size: 20px !important;
          font-weight: 600 !important;
          margin-bottom: 14px !important;
          z-index: 1 !important;
     }

     .parallax-detail-logo {
          font-size: 12px !important;
          color: #ccc !important;
          z-index: 1 !important;
          white-space: break-spaces !important;
          margin-bottom: 14px !important;
     }

     .parallax-btn-logo {
          border-radius: 40px !important;
          font-size: 12px !important;
          z-index: 1 !important;
     }

     /* --------------------- */
     /* ----- title-menu ------ */

     .title-menu {
          // margin-left: 1.25rem !important;
          font-size: 14px !important;
     }

     .btn-menu {
          border-radius: 40px !important;
          font-size: 14px !important;
     }

     .minor-header {
          top: 75px !important;
          z-index: 1 !important;
          width: 100vw !important;
          position: absolute !important;
     }
}

@media screen and (min-height: 691px) and (min-width: 600px) {
     .parallax-image-logo {
          width: auto !important;
          height: 120px !important;
          z-index: 1 !important;
          margin-top: 100px !important;
          object-fit: contain !important;
          margin-bottom: 24px !important;
     }

     .parallax-title-logo {
          color: #ffffff !important;
          font-size: 50px !important;
          font-weight: 600 !important;
          margin-bottom: 24px !important;
          z-index: 1 !important;
     }

     .parallax-detail-logo {
          font-size: 18px !important;
          color: #ccc !important;
          z-index: 1 !important;
          white-space: break-spaces !important;
          margin-bottom: 24px !important;
     }

     .parallax-btn-logo {
          border-radius: 40px !important;
          font-size: 18px !important;
          z-index: 1 !important;
     }

     /* --------------------- */
     /* ----- title-menu ------ */

     .title-menu {
          // margin-left: 1.25rem !important;
          font-size: 18px !important;
     }

     .btn-menu {
          border-radius: 40px !important;
          font-size: 18px !important;
     }

     .minor-header {
          top: 75px !important;
          z-index: 1 !important;
          width: 100vw !important;
          position: absolute !important;
     }
}

//-------------------------------------------------------------//
//-------------------------------------------------------------//
//-------------------------------------------------------------//
//------------------------ Login ------------------------------//
//-------------------------------------------------------------//
//-------------------------------------------------------------//
//-------------------------------------------------------------//
.mainlogin-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     background-position: center top;
     background-size: cover;
}

@media screen and (max-height: 390px) {
     .mainlogin-layout {
          height: 100%;
     }
}

@media screen and (min-height: 391px) {
     .mainlogin-layout {
          height: 100vh;
     }
}

.register-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     // background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     // background-position: center top;
     // background-size: cover;
}

@media screen and (max-height: 969px) {
     .register-layout {
          height: 100%;
     }
}

@media screen and (min-height: 970px) {
     .register-layout {
          height: 100vh;
     }
}

.ib_register-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     // background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     // background-position: center top;
     // background-size: cover;
}

@media screen and (max-height: 969px) {
     .ib_register-layout {
          height: 100%;
     }
}

@media screen and (min-height: 970px) {
     .ib_register-layout {
          height: 100vh;
     }
}

.login-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     margin-bottom: 60px;
     // background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     // background-position: center top;
     // background-size: cover;
}

@media screen and (max-height: 598px) {
     .login-layout {
          height: 100%;
     }
}

@media screen and (min-height: 599px) {
     .login-layout {
          height: 100vh;
     }
}

.forgot-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     // background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     // background-position: center top;
     // background-size: cover;
}

@media screen and (max-height: 598px) {
     .forgot-layout {
          height: 100%;
     }
}

@media screen and (min-height: 599px) {
     .forgot-layout {
          height: 100vh;
     }
}

//-------------------------------------------------------------//
//-------------------------------------------------------------//
//------------------------ password ---------------------------//
//-------------------------------------------------------------//
//-------------------------------------------------------------//
.reset-password-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     background-position: center top;
     background-size: cover;
}

.textfield-css-password input {
     color: #fff !important;
}

.textfield-css-password .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6;
}

.textfield-css-password .MuiOutlinedInput-root:focus {
     outline: unset !important;
}

.textfield-css-password .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}

.textfield-css-password .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #56B3E5 !important;
     border-width: 1px !important;
}

.textfield-css-password .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #4589C6 !important;
}

.textfield-css-password .MuiSelect-icon {
     top: calc(50% - 12px);
     color: #fff;
     position: absolute;
     pointer-events: none;
}

.textfield-css-password .MuiFormLabel-root.Mui-focused {
     color: #fff !important;
}

.textfield-css-password .MuiInputLabel-shrink {
     color: #fff !important;
}

.textfield-css-password .MuiSvgIcon-root {
     color: #fff !important;
     margin-right: 10px !important;
     margin-top: -2px !important;
}

.textfield-css-password ::placeholder {
     /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: #D4D4D4 !important;
     opacity: unset !important;
     /* Firefox */
}

.textfield-css-password :-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color: #fff !important;
}

.textfield-css-password ::-ms-input-placeholder {
     /* Microsoft Edge */
     color: #fff !important;
}

.textfield-css-password ::-ms-reveal {
     filter: invert(100%);
}

@media screen and (max-height: 598px) {
     .forgot-layout {
          height: 100%;
     }
}

@media screen and (min-height: 599px) {
     .forgot-layout {
          height: 100vh;
     }
}

//---------------------------------------------------------------------------------------------//
.verify-layout {
     width: 100vw;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     background: linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("../assets/images/main.png");
     background-position: center top;
     background-size: cover;
}

@media screen and (max-height: 598px) {
     .verify-layout {
          height: 100%;
     }
}

@media screen and (min-height: 599px) {
     .verify-layout {
          height: 100vh;
     }
}

//--------------------------//
.taps-css .css-1c6yvdb-MuiButtonBase-root-MuiTab-root.Mui-selected {
     color: #4589C6 !important;
     border-bottom: solid 4px #4589C6 !important;

     // background-color: #fc0316 !important;
}

.taps-css .css-1c6yvdb-MuiButtonBase-root-MuiTab-root {
     color: #707070 !important;
     background-color: transparent !important;
}

.taps-css .css-1l5yae9-MuiTabs-indicator {
     transition: unset !important;
     background-color: transparent !important;
}

.taps-css-mobile .css-1c6yvdb-MuiButtonBase-root-MuiTab-root.Mui-selected {
     color: #4589C6 !important;
     border-bottom: solid 4px #4589C6 !important;
     min-width: 90px !important;
     // background-color: #fc0316 !important;
}

.taps-css-mobile .css-1c6yvdb-MuiButtonBase-root-MuiTab-root {
     color: #707070 !important;
     background-color: transparent !important;
     min-width: 90px !important;
}

.taps-css-mobile .css-1l5yae9-MuiTabs-indicator {
     transition: unset !important;
     background-color: transparent !important;
     min-width: 90px !important;
}

.taps-css-mobile-trading .css-1c6yvdb-MuiButtonBase-root-MuiTab-root.Mui-selected {
     color: #4589C6 !important;
     border-bottom: solid 4px #4589C6 !important;
     min-width: 300px !important;
     // background-color: #fc0316 !important;
}

.taps-css-mobile-trading .css-1c6yvdb-MuiButtonBase-root-MuiTab-root {
     color: #4589C6 !important;
     background-color: transparent !important;
     min-width: 300px !important;
}

.taps-css-mobile-trading .css-1l5yae9-MuiTabs-indicator {
     transition: unset !important;
     background-color: transparent !important;
     min-width: 300px !important;
}

.taps-css-mobile-financail .css-1c6yvdb-MuiButtonBase-root-MuiTab-root.Mui-selected {
     color: #4589C6 !important;
     border-bottom: solid 4px #4589C6 !important;
     min-width: 190px !important;
     // background-color: #fc0316 !important;
}

.taps-css-mobile-financail .css-1c6yvdb-MuiButtonBase-root-MuiTab-root {
     color: #4589C6 !important;
     background-color: transparent !important;
     min-width: 190px !important;
}

.taps-css-mobile-financail .css-1l5yae9-MuiTabs-indicator {
     transition: unset !important;
     background-color: transparent !important;
     min-width: 190px !important;
}

//--------------------------------account------------------------------------//
.zoom {
     transition: transform 0.2s;
     /* Animation */
     margin: auto;
}

.zoom:hover {
     transform: scale(1.05);
}

.zoom-tranfer {
     transition: transform 0.2s;
     /* Animation */
     margin: auto;
}

.zoom-tranfer:hover {
     transform: scale(1.01);
}

//------------------------------------------Date Picker---------------------------------------------------//
.react-date-picker__wrapper {
     display: flex;
     flex-grow: 1;
     flex-shrink: 0;
     border: thin solid gray;
     border-radius: 5px;
}

.date-picker {
     height: 40px;
     width: 100%;
}

.top-to-btm {
     position: relative;
     display: flex !important;
     justify-content: center !important;
     justify-content: flex-end !important;
}

.icon-position {
     position: fixed;
     z-index: 9999999999;
}

.icon-style {
     // background-color: transparent !important;
     background-color: #fff !important;
     border: 2px solid #fff;
     // border-radius: 50%;
     height: 50px;
     width: 50px;
     cursor: pointer;
     transition: all .5s ease-in-out;
     box-shadow: 4px 4px 4px #a39e9eaa;
}

.full-img-modal-modal-content {
     margin: auto;
     background-color: #fff;
     position: relative;
     padding: 0;
     outline: 0;
}

@media screen and (max-width: 598px) {
     .full-img-modal-modal-content {
          width: auto;
     }
}

@media screen and (min-width: 599px) {
     .full-img-modal-modal-content {
          width: 400px;
     }
}

.animate-zoom {
     animation: animatezoom 0.6s
}

@keyframes animatezoom {
     from {
          transform: scale(0)
     }

     to {
          transform: scale(1)
     }
}

.zoom-out {
     object-fit: contain !important;
     height: 200px;
     cursor: pointer;
}

/////////////////////////// Promotion ///////////////////////////
.box-promotion-img {
     display: flex;
     justify-content: center;
     align-items: center;
     border-style: dotted;
     border-color: #4589C6;
     width: 300px;
     height: 200px;
}