/////////////////////// Sort ///////////////////////////
button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

button.ascending::after {
    color: aliceblue;
    content: '▼';
    display: inline-block;
    margin-left: 0.5em;
}

button.descending::after {
    color: aliceblue;
    content: '▲';
    display: inline-block;
    margin-left: 0.5em;
}
